import { CAN_USE_DOM } from 'constants/constants';
import globalsService from 'libs/services/globals';

class ScriptManagerService {
  getScriptNode(src: string): HTMLElement | null {
    if (!CAN_USE_DOM) return null;
    return document.querySelector(`script[src="${src}"]`);
  }

  /**
   * Mark/store the script as fully loaded in a global variable.
   * @param src URL of the script
   */
  markScriptFullyLoaded(src: string): void {
    if (!CAN_USE_DOM) return;
    globalsService.set(`scriptLoadMap.${src}`, true);
  }

  /**
   * Returns true if the script has been added to the page
   * @param src URL of the script
   */
  isScriptAdded(src: string): boolean {
    if (!CAN_USE_DOM) return false;
    return Boolean(this.getScriptNode(src));
  }

  /**
   * Returns true if the script has been fully loaded
   * @param src URL of the script
   */
  isScriptFullyLoaded(src: string): boolean {
    if (!CAN_USE_DOM) return false;
    return Boolean(globalsService.get(`scriptLoadMap.${src}`));
  }

  /**
   * Remove a script.
   * @param src URL of the script
   */
  unloadScript(entry: string | HTMLElement): void {
    const script = typeof entry === 'string' ? this.getScriptNode(entry) : entry;

    if (script?.parentNode) {
      script.parentNode.removeChild(script);
    }
  }

  /**
   * Load a script.
   * @param src URL of the script
   * @param onLoadCallback Callback function when the script is fully loaded
   * @param onLoadErrorCallback Callback function when the script fails to load
   * @param retryCount How many times retry laoding the script? (Not implimented here. Logic goes into js.onerror function)
   */
  loadScript(
    src: string,
    onLoadCallback?: (event?: Event) => unknown,
    onLoadErrorCallback?: (event?: Event) => unknown
  ): void {
    if (!src || !CAN_USE_DOM) return;

    // Check if the script is already loaded
    if (this.isScriptAdded(src)) {
      // If script already loaded successfully, trigger the callback function
      if (this.isScriptFullyLoaded(src) && onLoadCallback) onLoadCallback();

      console.warn('Script already loaded. Skipping: ', src);
      return;
    }

    // Loading the script...
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.src = src;

    script.onload = () => {
      this.markScriptFullyLoaded(src);

      // Optional callback on script load
      if (onLoadCallback) onLoadCallback();
    };

    script.onerror = () => {
      this.unloadScript(script);

      // Optional callback on script load failure
      if (onLoadErrorCallback) onLoadErrorCallback();
    };

    document.head.appendChild(script);
  }
}

const service = new ScriptManagerService();

export default service;
