/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import { StorageInterface } from '../core/storage/interfaces/storage.interface';
import { LocalStorageKeysEnum } from '../../constants/localStorageKeys';

class LocalStorage implements StorageInterface {
  public removeItem(key: LocalStorageKeysEnum): void {
    localStorage.removeItem(key);
  }

  public getItem<T>(key: LocalStorageKeysEnum): T | string {
    const item = localStorage.getItem(key);

    if (!item) return '';

    return JSON.parse(item);
  }

  public setItem(key: LocalStorageKeysEnum, value: any): void | undefined {
    if (value === undefined) {
      console.error("Local storage value can't be undefined");
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  }
}

export default new LocalStorage();
