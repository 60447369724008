/* eslint-disable class-methods-use-this */
import { LocalStorageKeysEnum } from '../../../../constants/localStorageKeys';
import { StorageInterface } from '../interfaces/storage.interface';
import { JWTokens, User } from '../../../../types/auth';

export class StorageService {
  constructor(private storage: StorageInterface) {}

  public removeJWTokens(): void {
    // TODO: Although the tokens are no longer stored in localStorage, I am leaving this code
    // In to make sure it is removed to all of our users, the code can be removed in the next iteration.
    this.removeAccessToken();
    this.removeRefreshToken();
  }

  public setJWTokens({ accessToken }: JWTokens): void {
    this.setAccessToken(accessToken);
  }

  public getTokens(): JWTokens {
    const accessToken = this.getAccessToken();

    return {
      accessToken,
      refreshToken: '',
    };
  }

  public getAccessToken(): string {
    return this.storage.getItem(LocalStorageKeysEnum.ACCESS_TOKEN);
  }

  public setAccessToken(token: string): void {
    return this.storage.setItem(LocalStorageKeysEnum.ACCESS_TOKEN, token);
  }

  public getUser(): User | string {
    return this.storage.getItem<User>(LocalStorageKeysEnum.USER);
  }

  public setUser(user: User): void {
    return this.storage.setItem<User>(LocalStorageKeysEnum.USER, user);
  }

  public removeUser(): void {
    return this.storage.removeItem(LocalStorageKeysEnum.USER);
  }

  public setScansLimitAlert(show: boolean): void {
    return this.storage.setItem(LocalStorageKeysEnum.SCANS_LIMIT, show);
  }

  public getScansLimitAlert(): string | boolean {
    return this.storage.getItem(LocalStorageKeysEnum.SCANS_LIMIT);
  }

  public setLokaliseEditor(show: boolean): void {
    return this.storage.setItem(LocalStorageKeysEnum.LOKALISE, show);
  }

  public getLokaliseEditor(): string | boolean {
    return this.storage.getItem(LocalStorageKeysEnum.LOKALISE);
  }

  public setActiveProductId(id: string): void {
    return this.storage.setItem(LocalStorageKeysEnum.ACTIVE_PRODUCT, id);
  }

  public getActiveProductId(): string {
    return this.storage.getItem(LocalStorageKeysEnum.ACTIVE_PRODUCT);
  }

  public removeLokaliseEditor(): void {
    return this.storage.removeItem(LocalStorageKeysEnum.LOKALISE);
  }

  private removeRefreshToken(): void {
    return this.storage.removeItem(LocalStorageKeysEnum.REFRESH_TOKEN);
  }

  private removeAccessToken(): void {
    return this.storage.removeItem(LocalStorageKeysEnum.ACCESS_TOKEN);
  }

  public setSuccessPaymentPageVisibility(show: boolean): void {
    return this.storage.setItem(LocalStorageKeysEnum.SUCCESS_PAYMENT_PAGE_VISIBILITY, show);
  }

  public getSuccessPaymentPageVisibility(): string | boolean {
    return this.storage.getItem(LocalStorageKeysEnum.SUCCESS_PAYMENT_PAGE_VISIBILITY);
  }

  public removeSuccessPaymentPageVisibility(): void {
    return this.storage.removeItem(LocalStorageKeysEnum.SUCCESS_PAYMENT_PAGE_VISIBILITY);
  }
}
