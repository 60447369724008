export const HEX_COLOR_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/im;

export const HEX_COLOR = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/im;

export const PASSWORD = /^(?=.*?[a-zA-Z])(?=.*?[0-9])[A-Za-z\d@$!%*|?&+=#^{}:;"'<,.>/№`~\-_]{8,100}$/;

export const URL_WITH_OPTIONAL_PROTOCOL_PART =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/im;

export const STARTS_WITH_HTTP = /^(http|https):\/\//im;

export const PHONE_NUMBER = /^[+]?[0-9]{1,15}$/im;

export const POSTAL_CODE = /^[a-z0-9][a-z0-9\- ]{0,13}[a-z0-9]$/im;

export const EMAIL_5322 =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

export const CARD_HOLDER = /^(?:[A-Za-z]+ ?){1,3}$/gm;
