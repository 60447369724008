/* eslint-disable no-console */
import { TemplateCategoryDTO } from '../types';

export const createTemplateCategoryDTO = (entry: TemplateCategoryDTO): TemplateCategoryDTO | null => {
  if (!entry.type) {
    console.error('QR code option should contain type');
    return null;
  }

  return {
    id: entry.id || '',
    createdAt: entry.createdAt || '',
    updatedAt: entry.updatedAt || '',
    image: entry.image || '',
    title: entry.title || '',
    description: entry.description || '',
    type: entry.type,
  };
};
