import objectPath from 'object-path';

class GlobalsService {
  set = (path: string, value: unknown): void => {
    window.MyQRCode = window.MyQRCode || {};
    objectPath.set(window.MyQRCode, path, value);
  };

  get = (path: string): unknown => {
    if (!window.MyQRCode) return undefined;
    return objectPath.get(window.MyQRCode, path);
  };

  delete = (path: string): unknown => {
    if (!window.MyQRCode) return true;
    return objectPath.del(window.MyQRCode, path);
  };
}

const service = new GlobalsService();

export default service;
