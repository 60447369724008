import React, { PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { EventBase, EventEmitter, EventMapping } from 'services/EventEmitter';

export enum EventTypes {
  MaliciousUrlsInQRCode = 'malicious-urls-in-qr-code',
  QrLeadsToMalware = 'qr-leads-to-malware',
}

export type MaliciousUrlsInQRCodeDTO = {
  type: EventTypes.MaliciousUrlsInQRCode;
  details: {
    urls: string[];
  };
};

export type QrLeadsToMalware = {
  type: EventTypes.QrLeadsToMalware;
};

export type DefaultAppEvents = MaliciousUrlsInQRCodeDTO | QrLeadsToMalware;

export const globalEventEmitter = new EventEmitter<DefaultAppEvents>();

export type EventEmitterProviderProps<T extends EventBase = DefaultAppEvents> = {
  eventEmitter?: EventEmitter<T>;
};

export type EventEmitterContextValue<T extends EventBase = DefaultAppEvents> = {
  eventEmitter: EventEmitter<T>;
};

export const EventEmitterContext = React.createContext<EventEmitterContextValue>({} as EventEmitterContextValue);

export const useEventEmitter = <T extends EventBase = DefaultAppEvents>() => {
  const { eventEmitter } = useContext(EventEmitterContext) as EventEmitterContextValue<T>;

  return eventEmitter;
};

export const useEventListener = <
  T extends EventBase = DefaultAppEvents,
  K extends keyof EventMapping<T> = keyof EventMapping<DefaultAppEvents>
>(
  eventName: K,
  listener: (event: EventMapping<T>[K]) => void
) => {
  const eventEmitter = useEventEmitter<T>();

  useEffect(() => {
    const subscription = eventEmitter.addEventListener(eventName, listener);

    return () => {
      subscription.unsubscribe();
    };
  }, [eventEmitter, eventName, listener]);
};

export const EventEmitterProvider = <T extends EventBase = DefaultAppEvents>(
  props: PropsWithChildren<EventEmitterProviderProps<T>>
) => {
  const { children, eventEmitter = globalEventEmitter } = props;

  const value = useMemo(() => ({ eventEmitter }), [eventEmitter]);

  return (
    <EventEmitterContext.Provider value={value as EventEmitterContextValue}>{children}</EventEmitterContext.Provider>
  );
};
