/**
 * Transforms object values using provided transformers
 * @param object - object to transform
 * @param transformers - transformers to use
 * @returns transformed object
 * @example
 * const object = { a: 1, b: 2 };
 *
 * const transformedObject = transformObject(
 *  object,
 *  {
 *    a: (value) => value + 1,
 *    b: (value) => value.toString()
 *  }
 * );
 *
 * // transformedObject = { a: 2, b: '2' };
 */

export const transformObject = <
  T extends Record<string, unknown>,
  Transformers extends { [key in keyof T]?: (value: T[key]) => unknown }
>(
  object: T,
  transformers: Transformers
): { [key in keyof T]: Transformers[key] extends (value: T[key]) => infer U ? U : T[key] } => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    const transform = transformers[key as keyof Transformers];

    if (!transform) {
      acc[key as keyof T] = value;

      return acc;
    }

    acc[key as keyof T] = transform(value as any);

    return acc;
  }, {} as any);
};

export const getCookie = (name: string) => {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${name}=`))
    ?.split('=')[1];
};
