import { User } from 'types/auth';
import { UserStatusEnum } from 'types/subscriptions';
import { UserRoleEnum } from 'constants/userAccess';

export const checkPermissions = (user: User | null | false, permissions?: UserStatusEnum[]): boolean => {
  if (!user) return false;
  if (!user.status) return true;

  if (!permissions?.length) return true;

  return permissions.includes(user.status);
};

export const checkRole = (user: User | null | false, roles?: UserRoleEnum): boolean => {
  if (!user) return false;

  if (!roles?.length) return true;

  return roles.includes(user.role);
};
