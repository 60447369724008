import { AddressDTO } from '../types';

export const createAddressDTO = (entry?: Partial<AddressDTO>): AddressDTO => {
  return {
    country: entry?.country || '',
    state: entry?.state || '',
    city: entry?.city || '',
    street: entry?.street || '',
    postalCode: entry?.postalCode || '',
  };
};
