import { join } from 'utils';
import { ROUTES } from 'constants/api.routes';
import { BASE_URL } from 'constants/api';
import { AxiosResponse } from 'axios';
import { axiosApiInstance } from 'api/axios';
import { QRCodeDTO } from '../types';
import { createQRCodeDTO } from '../entities';

class CommonQRCodeApi {
  public async getByURI(uri: string): Promise<QRCodeDTO | undefined> {
    const url = join(ROUTES.QR_CODES_API.URI, '/', uri);

    const { data }: AxiosResponse<QRCodeDTO> = await axiosApiInstance.get(url);

    return data ? createQRCodeDTO(data) : undefined;
  }

  public buildVCardFileLink(viewURI: string): string {
    return join(BASE_URL, ROUTES.QR_CODES_API.COMMON.VCARD, '/', viewURI);
  }
}

const commonQRCodeApi = new CommonQRCodeApi();

export default commonQRCodeApi;
