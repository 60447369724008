import { QRCodeTypeEnum } from 'types/qrCodes';

export enum EncryptionEnum {
  WEP = 'WEP',
  WPA = 'WPA',
  'WPA-EAP' = 'WPA-EAP',
  NOPASS = 'nopass',
}

export type WiFiDTO = {
  type: QRCodeTypeEnum.WIFI;
  name: string;
  ssid: string;
  encryption: `${EncryptionEnum}`;
  password: string;
  hidden: boolean;
};
