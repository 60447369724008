import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import { WiFiDTO, EncryptionEnum } from '../types/WiFiDTO';

export const createWiFiDTO = (entry?: RecursivePartial<WiFiDTO>): WiFiDTO => {
  return {
    type: QRCodeTypeEnum.WIFI,
    name: entry?.name || '',
    ssid: entry?.ssid || '',
    encryption: entry?.encryption || EncryptionEnum.WPA,
    password: entry?.password || '',
    hidden: entry?.hidden ?? false,
  };
};
