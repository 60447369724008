export enum LocalStorageKeysEnum {
  LANGUAGE_KEY = 'i18nextLng',
  USER = 'user',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  SCANS_LIMIT = 'scans_limit',
  LOKALISE = 'lokalise',
  ACTIVE_PRODUCT = 'active_product',
  SUCCESS_PAYMENT_PAGE_VISIBILITY = 'success_payment_page_visibility',
}

export type LocalStorageKeys = keyof typeof LocalStorageKeysEnum;
