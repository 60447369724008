import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import nextId from 'react-id-generator';

type ErrorPayload = {
  message: string;
};

type Error = {
  id: string;
  message: string;
};

type UiState = {
  isFetching: boolean;

  errors: Error[];
};

const initialState: UiState = {
  isFetching: false,

  errors: [],
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    addError: (state, { payload: { message } }: PayloadAction<ErrorPayload>) => {
      state.errors = [...state.errors, { message, id: nextId() }];
    },
    deleteError: (state, { payload }: PayloadAction<Error['id']>) => {
      state.errors = state.errors.filter((error) => error.id !== payload);
    },
  },
});

export const { addError, deleteError } = uiSlice.actions;

export default uiSlice.reducer;
