import { RecursivePartial } from 'types';
import { MediaProviders, CloudinaryProviderMediaDetailsDTO } from '../types';

const createCloudinaryProviderMediaDetailsDTO = (
  entry?: RecursivePartial<CloudinaryProviderMediaDetailsDTO> | null
): CloudinaryProviderMediaDetailsDTO => {
  return {
    provider: MediaProviders.CLOUDINARY,
    publicId: entry?.publicId || '',
    resourceType: entry?.resourceType || '',
    format: entry?.format || '',
    bytes: entry?.bytes || 0,
  };
};

export default createCloudinaryProviderMediaDetailsDTO;
