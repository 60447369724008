import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import { WebsiteDTO } from '../types';

export const createWebsiteDTO = (entry?: RecursivePartial<WebsiteDTO>): WebsiteDTO => {
  return {
    type: QRCodeTypeEnum.URL,
    name: entry?.name || '',
    url: entry?.url || '',
  };
};
