import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import createCloudinaryMediaDetailsDTO from 'api/media/entities/createCloudinaryMediaDetailsDTO';
import { createButtonDTO } from './createButtonDTO';
import { AppDTO, PlatformLinkDto } from '../types';

const createPlatformLink = (entry?: Partial<PlatformLinkDto>): PlatformLinkDto => {
  return { url: entry?.url || '', name: entry?.name || '' };
};

export const createAppDTO = (entry?: RecursivePartial<AppDTO>): AppDTO => {
  return {
    type: QRCodeTypeEnum.APP,
    name: entry?.name || '',
    loaderImage: createCloudinaryMediaDetailsDTO(entry?.loaderImage),
    colors: {
      primary: entry?.colors?.primary || '',
      secondary: entry?.colors?.secondary || '',
    },

    appInfo: {
      name: entry?.appInfo?.name || '',
      developer: entry?.appInfo?.developer || '',
      description: entry?.appInfo?.description || '',
      logo: createCloudinaryMediaDetailsDTO(entry?.appInfo?.logo),
      buttons: (entry?.appInfo?.buttons || []).map((button) => createButtonDTO(button)),
    },
    platformLinks: (entry?.platformLinks || []).map((linkEntry) => createPlatformLink(linkEntry)),
  };
};
