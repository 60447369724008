import { axiosApiInstance } from 'api/axios';
import { ROUTES } from 'constants/api.routes';
import { DefaultApiResponse } from 'types';

export type TranslationRecordDTO = {
  key: string;
  translation: string;
  locale: string;
};

export type TranslationUpdate = {
  items: TranslationRecordDTO[];
};

class LokaliseApi {
  public async update(update: TranslationUpdate): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.put(ROUTES.TRANSLATIONS.UPDATE, update);

    return data;
  }
}

export default new LokaliseApi();
