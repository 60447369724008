import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountSettings, Language } from 'types/account';
import { fetchAccount, fetchCountries, fetchLanguages, fetchNativeLanguages, fetchTimezones } from './thunks';

export type AccountState = {
  account: AccountSettings | null;
  languages: Language[];
  nativeLanguages: Language[];
  timezones: { name: string; displayName: string }[];
  nativeLanguagesFetched: boolean;
  countries: Language[];
  countriesFetched: string;
};

export type CountriesPayload = {
  language: string;
  countries: Language[];
};

const initialState: AccountState = {
  account: null,
  languages: [],
  nativeLanguages: [],
  timezones: [],
  countries: [],
  nativeLanguagesFetched: false,
  countriesFetched: '',
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state, { payload }: PayloadAction<AccountSettings>) => {
      state.account = payload;
    },
    setNativeLanguages: (state, { payload }: PayloadAction<Language[]>) => {
      state.nativeLanguages = payload;
      state.nativeLanguagesFetched = payload != null && payload.length > 0;
    },
    setCountries: (state, { payload }: PayloadAction<CountriesPayload>) => {
      state.countries = payload.countries;
      state.countriesFetched = payload.language;
    },
    checkNativeLanguages: (state) => {
      state.nativeLanguagesFetched = state.nativeLanguages != null && state.nativeLanguages.length > 0;
    },
    // This is just a helper function to trigger the middleware
    checkCountries: (state, { payload }: PayloadAction<string>) => {},
  },
  extraReducers: (account) => {
    account
      .addCase(fetchAccount.fulfilled, (state, action) => {
        state.account = action.payload;
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        state.languages = action.payload;
      })
      .addCase(fetchNativeLanguages.fulfilled, (state, action) => {
        state.nativeLanguages = action.payload;
        state.nativeLanguagesFetched = true;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries = action.payload;
        state.countriesFetched = action.meta.arg;
      })
      .addCase(fetchTimezones.fulfilled, (state, action) => {
        state.timezones = action.payload;
      });
  },
});

export const { setAccount, checkNativeLanguages, checkCountries, setCountries } = accountSlice.actions;

export default accountSlice.reducer;
