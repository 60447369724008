export type EventBase = {
  type: string;
  details?: any;
};

export type EventMapping<T extends EventBase> = {
  [key in T['type']]: Extract<T, { type: key }>;
};

export type EventListener<T extends EventBase> = (event: T) => void | Promise<void>;

export type Subscription = {
  unsubscribe: VoidFunction;
};

export class EventEmitter<E extends EventBase> {
  private listeners: {
    [key in keyof EventMapping<E>]?: Set<EventListener<EventMapping<E>[key]>>;
  } = {};

  private subscriptions: Map<EventListener<E>, Subscription> = new Map();

  public addEventListener = <T extends keyof EventMapping<E>>(
    type: T,
    listener: (event: EventMapping<E>[T]) => void | Promise<void>
  ): Subscription => {
    let listeners = this.listeners[type];

    if (!listeners) {
      this.listeners[type] = new Set();

      listeners = this.listeners[type];
    }

    if (listeners?.has(listener)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.subscriptions.get(listener as any)!;
    }

    const subscription = {
      unsubscribe: () => {
        listeners?.delete(listener);

        this.subscriptions.delete(listener as any);
      },
    };

    listeners?.add(listener);

    this.subscriptions.set(listener as any, subscription);

    return subscription;
  };

  public dispatchEvent = <T extends keyof EventMapping<E>>(event: EventMapping<E>[T]): void => {
    const listeners = this.listeners[event.type];

    if (!listeners) {
      return;
    }

    listeners.forEach((listener) => {
      listener(event);
    });
  };
}
