import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import createCloudinaryMediaDetailsDTO from 'api/media/entities/createCloudinaryMediaDetailsDTO';
import { createButtonDTO } from './createButtonDTO';
import { VideoDTO, PlatformVideoDTO } from '../types/VideoDTO';
import { createColorsDTO } from './createColorsDTO';

export const createPlatformVideoDTO = (entry?: RecursivePartial<PlatformVideoDTO>): PlatformVideoDTO => {
  return {
    name: entry?.name || '',
    description: entry?.description || '',
    source: {
      url: entry?.source?.url || null,
      uploaded: entry?.source?.uploaded ? createCloudinaryMediaDetailsDTO(entry?.source?.uploaded) : null,
    },
  };
};

export const createVideoDTO = (entry?: RecursivePartial<VideoDTO>): VideoDTO => {
  return {
    type: QRCodeTypeEnum.VIDEO,
    name: entry?.name || '',
    loaderImage: createCloudinaryMediaDetailsDTO(entry?.loaderImage),
    colors: createColorsDTO(entry?.colors),
    info: {
      title: entry?.info?.title || '',
      description: entry?.info?.description || '',
      buttons: (entry?.info?.buttons || []).map((button) => createButtonDTO(button)),
    },
    videos: (entry?.videos || []).map((video) => createPlatformVideoDTO(video)),
    isShareable: entry?.isShareable ?? true,
  };
};
