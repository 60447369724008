import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import createCloudinaryMediaDetailsDTO from 'api/media/entities/createCloudinaryMediaDetailsDTO';
import { MenuDTO, MenuSectionDTO, MenuProductDTO } from '../types/menuDTO';

type Entry = RecursivePartial<Omit<MenuDTO, 'sections'>> & Partial<Pick<MenuDTO, 'sections'>>;

export const createMenuProductDTO = (entry?: Partial<MenuProductDTO>): MenuProductDTO => {
  return {
    image: createCloudinaryMediaDetailsDTO(entry?.image),
    name: entry?.name || '',
    nameTranslation: entry?.nameTranslation || '',
    description: entry?.description || '',
    descriptionTranslation: entry?.descriptionTranslation || '',
    price: entry?.price || '',
    allergens: entry?.allergens || [],
  };
};

export const createMenuSectionDTO = (entry?: Partial<MenuSectionDTO>): MenuSectionDTO => {
  return {
    name: entry?.name || '',
    nameTranslation: entry?.nameTranslation || '',
    description: entry?.description || '',
    descriptionTranslation: entry?.descriptionTranslation || '',
    products: (entry?.products || [undefined]).map(createMenuProductDTO),
  };
};

export const createMenuDTO = (entry?: Entry): MenuDTO => {
  return {
    type: QRCodeTypeEnum.MENU,
    name: entry?.name || '',
    colors: {
      primary: entry?.colors?.primary || '',
      secondary: entry?.colors?.secondary || '',
    },
    info: {
      name: entry?.info?.name || '',
      description: entry?.info?.description || '',
      image: createCloudinaryMediaDetailsDTO(entry?.info?.image),
    },
    sections: (entry?.sections || [undefined]).map(createMenuSectionDTO),
    loaderImage: createCloudinaryMediaDetailsDTO(entry?.loaderImage),
  };
};
