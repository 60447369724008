import { FC, memo, useEffect, useRef } from 'react';

import type { AppProps } from 'next/app';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'styles/MUITheme';
import { store } from 'store';
import TranslationEditorProvider from 'providers/TranslationEditor';
import { Provider } from 'react-redux';
import { globalStyles } from 'styles/global';
import Head from 'next/head';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from 'utils/ssrStylesCache';
import { EmotionCache } from '@emotion/cache';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import i18n, { changeLanguage } from 'config/i18n';
// eslint-disable-next-line import/no-duplicates
import 'utils/index';
import { EventEmitterProvider } from 'providers/EventEmitterProvider';
import { useRouter } from 'next/router';
import { userSettingsApi } from 'api/userSettings';
import { ALLOWED_LANGUAGES } from 'constants/languages';
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const MyApp: FC<AppProps & { emotionCache: EmotionCache }> = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}) => {
  const router = useRouter();
  const previousPath = useRef<string>('');

  useEffect(() => {
    const handleRouteChange = (url: string, payload: boolean) => {
      if (payload) {
        previousPath.current = url;
      }
    };

    router.events.on('routeChangeStart', (url) => handleRouteChange(url, true));
    router.events.on('routeChangeComplete', (url) => handleRouteChange(url, false));

    const handleBeforeUnload = () => {
      const path = window.location.pathname;
      if (path.endsWith('/pricing') || path.endsWith('/checkout')) {
        userSettingsApi.getAbandonedCart();
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        handleBeforeUnload();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('visibilitychange', handleVisibilityChange);

    const handlePopState = (target: string) => {
      if (previousPath.current.endsWith('/pricing') && !target.endsWith('/pricing')) {
        userSettingsApi.getAbandonedCart();
      }
      const languageFromOptions = target?.split('/')[1];
      const languageToChange =
        languageFromOptions !== '' && languageFromOptions.length === 2 ? languageFromOptions : 'en';

      if (ALLOWED_LANGUAGES.includes(languageToChange) && languageToChange !== i18n.language) {
        changeLanguage(languageToChange);
      }

      return true;
    };

    router.beforePopState((options) => {
      return handlePopState(options.as);
    });

    return () => {
      router.beforePopState(() => true);
      router.events.off('routeChangeStart', (url) => handleRouteChange(url, true));
      router.events.off('routeChangeComplete', (url) => handleRouteChange(url, false));
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [router]);
  return (
    <EventEmitterProvider>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <CacheProvider value={emotionCache}>
        {globalStyles}
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <TranslationEditorProvider>
              <CssBaseline />
              {!(pageProps.removeAlt ?? false) && <ToastContainer />}
              <Component {...pageProps} />
            </TranslationEditorProvider>
          </ThemeProvider>
        </Provider>
      </CacheProvider>
    </EventEmitterProvider>
  );
};

export default memo(MyApp);
