import { Middleware } from '@reduxjs/toolkit';
import { fetchCountries, fetchNativeLanguages } from 'store/account/thunks';
import UserSettingsCache from 'utils/userSettingsCache';
import { accountSlice, checkCountries, checkNativeLanguages } from 'store/account';
import { AppDispatch } from 'store';

const userSettingsMiddleware: Middleware =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === checkCountries.type) {
      const cache = UserSettingsCache.getInstance();
      const cachedCountries = cache.getCountries(action.payload);
      if (cachedCountries) {
        dispatch(accountSlice.actions.setCountries({ countries: cachedCountries, language: action.payload }));
      } else {
        const result = await dispatch(fetchCountries(action.payload));
        if (fetchCountries.fulfilled.match(result)) {
          cache.setCountries(action.payload, result.payload);
          dispatch(accountSlice.actions.setCountries({ countries: result.payload, language: action.payload }));
        }
      }
    }
    if (action.type === checkNativeLanguages.type) {
      const cache = UserSettingsCache.getInstance();
      const cachedLanguages = cache.getLanguages();

      if (cachedLanguages) {
        dispatch(accountSlice.actions.setNativeLanguages(cachedLanguages));
      } else {
        const result = await dispatch(fetchNativeLanguages());
        if (fetchNativeLanguages.fulfilled.match(result)) {
          cache.setLanguages(result.payload);
        }
      }
    }
    return next(action);
  };

export default userSettingsMiddleware;
