import { RecursivePartial } from 'types';
import { GradientDTO } from '../types';
import { createGradientColorBoundaryDTO } from './createGradientColorBoundaryDTO';

export const createGradientDTO = (entry?: RecursivePartial<GradientDTO>): GradientDTO => {
  return {
    type: entry?.type || 'linear',
    rotation: entry?.rotation || 0,
    colorStops: (entry?.colorStops || []).map((colorBoundaryEntry) =>
      createGradientColorBoundaryDTO(colorBoundaryEntry)
    ),
  };
};
