import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QRCodeDTO } from 'api/qrCode/types';
import { TemplateCategoryDTO } from 'api/templateCategories/types';
import {
  getTemplateCategories,
  getPreviewQRCode,
  getGuestPreviewQRCode,
  getPermanentQRCode,
  getEditQRCodes,
  createQRCode,
  createGuestQRCode,
  updateQRCode,
  updateGuestQRCode,
} from './thunks';

interface BuilderState {
  templateCategories: TemplateCategoryDTO[];
  QRCode?: QRCodeDTO;
  permanentQRCode?: QRCodeDTO;
  unsavedQRCode?: QRCodeDTO;
  unsavedQRCodeUpdateTarget?: QRCodeDTO;
}

// Define the initial state using that type
const initialState: BuilderState = {
  templateCategories: [],
};

export const builderSlice = createSlice({
  name: 'builder',
  initialState,
  reducers: {
    setTemplateCategories: (state, action: PayloadAction<TemplateCategoryDTO[]>) => {
      state.templateCategories = action.payload;
    },
    setQRCode: (state, action: PayloadAction<QRCodeDTO | undefined>) => {
      state.QRCode = action.payload;
    },
    setPermanentQRCode: (state, action: PayloadAction<QRCodeDTO | undefined>) => {
      state.permanentQRCode = action.payload;
    },
    setUnsavedQRCode: (state, action: PayloadAction<QRCodeDTO | undefined>) => {
      state.unsavedQRCode = action.payload;
    },
    setUnsavedQRCodeUpdateTarget: (state, action: PayloadAction<QRCodeDTO | undefined>) => {
      state.unsavedQRCodeUpdateTarget = action.payload;
    },
    resetQrCodesStore: (state) => {
      state.QRCode = undefined;
      state.permanentQRCode = undefined;
      state.unsavedQRCode = undefined;
      state.unsavedQRCodeUpdateTarget = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplateCategories.fulfilled, (state, action) => {
        state.templateCategories = action.payload;
      })
      .addCase(getPreviewQRCode.fulfilled, (state, action) => {
        state.QRCode = action.payload;
      })
      .addCase(getGuestPreviewQRCode.fulfilled, (state, action) => {
        state.QRCode = action.payload;
      })
      .addCase(createQRCode.fulfilled, (state, action) => {
        state.QRCode = action.payload;
      })
      .addCase(createGuestQRCode.fulfilled, (state, action) => {
        state.QRCode = action.payload;
      })
      .addCase(updateQRCode.fulfilled, (state, action) => {
        state.QRCode = action.payload;
      })
      .addCase(updateGuestQRCode.fulfilled, (state, action) => {
        state.QRCode = action.payload;
      })
      .addCase(getEditQRCodes.fulfilled, (state, action) => {
        state.permanentQRCode = action.payload.permanentQRCode;
        state.QRCode = action.payload.QRCode;
      })
      .addCase(getPermanentQRCode.fulfilled, (state, action) => {
        state.permanentQRCode = action.payload;
      });
  },
});

export const {
  resetQrCodesStore,
  setPermanentQRCode,
  setQRCode,
  setTemplateCategories,
  setUnsavedQRCode,
  setUnsavedQRCodeUpdateTarget,
} = builderSlice.actions;

export default builderSlice.reducer;
