/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showNotification } from 'config/toast';
import { getValidErrorMessage } from 'utils';
import { localStorageService } from 'libs/localStorage/local.storage.service';
import { subscriptionApi } from 'api/subscription';
import { setActiveProduct } from '.';
import { SubscriptionProduct } from 'types/subscriptions';

export const getActiveProduct = createAsyncThunk('subscription/get_active_product', async (_, { dispatch }) => {
  try {
    const products = await subscriptionApi.getSubscriptionProductsForUser();
    const activeProductId = localStorageService.getActiveProductId();
    const product = products.find((item) => item.id === activeProductId);

    if (product) {
      dispatch(setActiveProduct(product));
    }
  } catch (error: any) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});

export const fetchSubscriptionProduct = createAsyncThunk<
  SubscriptionProduct[],
  | {
      currencyCode?: string;
      experimental?: boolean;
    }
  | undefined
>('subscription/product', async ({ currencyCode, experimental } = {}) => {
  try {
    return subscriptionApi.getSubscriptionProductsForUser(currencyCode, experimental);
  } catch (error: any) {
    console.error(error);

    showNotification(getValidErrorMessage(error), 'error');

    return error;
  }
});

export const fetchCurrencies = createAsyncThunk('subscription/currencies', async (_, { dispatch }) => {
  try {
    return subscriptionApi.getCurrencies();
  } catch (error: any) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});

export const fetchCurrentUserSubscription = createAsyncThunk('subscription/subscription', async () => {
  try {
    return subscriptionApi.getCurrentSubscription();
  } catch (error: any) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});

export const fetchUserTransaction = createAsyncThunk('subscription/transactions', async () => {
  try {
    return subscriptionApi.getTransactions();
  } catch (error: any) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});

export const fetchPaymentDetails = createAsyncThunk('subscription/payment_details', async () => {
  try {
    return subscriptionApi.paymentDetails();
  } catch (error: any) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});

export const fetchSubscriptionCommonProduct = createAsyncThunk('subscription/product', async (_, { dispatch }) => {
  try {
    return subscriptionApi.getSubscriptionProduct();
  } catch (error: any) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});
