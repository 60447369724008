import { FC, createContext, useContext, useMemo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth/selectors';
import { checkRole } from 'utils/permissions';
import { UserRoleEnum } from 'constants/userAccess';
import scriptManager from 'libs/services/scriptManager';
import { LOKALIZE_EDITOR_SCRIPT_SRC, LOKALISE_PROJECT_ID } from 'constants/constants';
import { localStorageService } from 'libs/localStorage/local.storage.service';
import { LokaliseConfig } from 'types/lokalise';
import { User } from 'types/auth';
import lokaliseApi from 'api/lokalise';
import { DEFAULT_LOCALE } from 'constants/languages';

export enum DataType {
  PLACEHOLDER = 'placeholder',
  LABEL = 'arial-label',
  INPUT = 'input',
  TITLE = 'title',
}

export type DataAttributes = {
  ['data-lokalise']: true;
  ['data-key']: string;
  ['data-type-placeholder']?: true;
  ['data-type-arial-label']?: true;
  ['data-type-input']?: true;
  ['data-type-title']?: true;
};

type TranslationEditorContextValue = {
  getDataAttributes: (key: string, type?: `${DataType}`) => DataAttributes;
  refresh: () => void;
  isTranslator: boolean;
};

export const TranslationEditorContext = createContext<TranslationEditorContextValue>(
  null as unknown as TranslationEditorContextValue
);

export const useTranslationEditorContext = (): TranslationEditorContextValue => {
  const context = useContext(TranslationEditorContext);
  return context;
};

const TranslationEditorProvider: FC<unknown> = ({ children }) => {
  const { i18n } = useTranslation('ns1', { useSuspense: false });

  const user = useSelector(selectUser);
  const refs = useRef({ i18n });

  useEffect(() => {
    refs.current.i18n = i18n;
  }, [i18n]);

  const getDataAttributes = useCallback((key: string, type?: `${DataType}`) => {
    const truthy = true as const;
    return {
      'data-lokalise': truthy,
      'data-key': key,
      ...(type ? { [`data-type-${type}`]: true } : {}),
    };
  }, []);

  const refresh = useCallback(() => {
    document.dispatchEvent(new Event('lokalise-update-elements'));
  }, []);

  const updateLocale = useCallback((lang: string) => {
    document.dispatchEvent(new CustomEvent('lokalise-update-locale', { detail: lang }));
  }, []);

  const editorLogoClickListener = useCallback(({ target }: PointerEvent | MouseEvent) => {
    if (!target) {
      return;
    }
    const liveJSLogo = target as Element;

    if (liveJSLogo.classList.contains('lokalise-live-js-logo')) {
      const liveJSContainer = document.getElementById('lokalise-live-js-panel');
      if (!liveJSContainer) {
        return;
      }
      if (liveJSContainer.classList.contains('flip')) {
        liveJSContainer.classList.remove('flip');
      } else {
        liveJSContainer.classList.add('flip');
      }
    }
  }, []);
  const isTranslator = checkRole(user as User, UserRoleEnum.TRANSLATOR);

  useEffect(() => {
    i18n.off('languageChanged', updateLocale);
    i18n.on('languageChanged', updateLocale);

    document.addEventListener('click', editorLogoClickListener);

    return () => {
      i18n.off('languageChanged', updateLocale);
      document.removeEventListener('click', editorLogoClickListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n]);

  const initEditor = useCallback(async () => {
    const currentUser = user ?? (localStorageService.getUser() as User);
    if (checkRole(currentUser, UserRoleEnum.TRANSLATOR)) {
      const locale = refs.current.i18n.resolvedLanguage || DEFAULT_LOCALE;
      window.LOKALISE_CONFIG = window.LOKALISE_CONFIG || ({} as LokaliseConfig);
      const config = window.LOKALISE_CONFIG;
      localStorageService.setLokaliseEditor(true);
      config.projectId = LOKALISE_PROJECT_ID;
      config.locale = locale;
      config.usePanelOffset = true;
      config.onSave = (updatedTranslations) => {
        // This array is sent to the server to reset updated translation key statuses from 'reviewed' to 'unreviewed'.
        // Timeout is required to fix the race condition that causes random status updates.
        window.setTimeout(() => {
          lokaliseApi.update({
            items: updatedTranslations.map(({ key, translation, locale }) => ({
              key,
              translation,
              locale,
            })),
          });
        }, 2e3);
      };
      scriptManager.loadScript(LOKALIZE_EDITOR_SCRIPT_SRC);
    } else if (window.LOKALISE_CONFIG) {
      window.LOKALISE_CONFIG = undefined;
      localStorageService.setLokaliseEditor(false);
      scriptManager.unloadScript(LOKALIZE_EDITOR_SCRIPT_SRC);
      const liveJSContainer = document.getElementById('lokalise-live-js-panel');
      liveJSContainer?.remove();
    }
  }, [user]);

  useEffect(() => {
    initEditor();
  }, [initEditor, user]);

  const providerValue = useMemo(
    () => ({
      getDataAttributes,
      refresh,
      isTranslator,
    }),
    [getDataAttributes, refresh, isTranslator]
  );

  return <TranslationEditorContext.Provider value={providerValue}>{children}</TranslationEditorContext.Provider>;
};

export default TranslationEditorProvider;
