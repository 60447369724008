import { RecursivePartial } from 'types';
import { MediaWithProviderDetailsDTO } from '../types';
import { isCloudinaryProviderMediaDetailsDTO, isNoProviderMediaDetailsDTO } from '../guards';
import createCloudinaryProviderMediaDetailsDTO from './createCloudinaryProviderMediaDetailsDTO';
import createNoProviderMediaDetailsDTO from './createNoProviderMediaDetailsDTO';

const createMediaWithProviderDetailsDTO = (
  entry?: RecursivePartial<MediaWithProviderDetailsDTO> | null
): MediaWithProviderDetailsDTO => {
  if (isCloudinaryProviderMediaDetailsDTO(entry)) {
    return createCloudinaryProviderMediaDetailsDTO(entry);
  }
  if (isNoProviderMediaDetailsDTO(entry)) {
    return createNoProviderMediaDetailsDTO(entry);
  }
  return createNoProviderMediaDetailsDTO();
};

export default createMediaWithProviderDetailsDTO;
