import { createTheme, PaletteColor, PaletteOptions } from '@mui/material/styles';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string): PaletteColor => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  typography: {
    fontFamily: "'Rubik', sans-serif",
    htmlFontSize: 16,
  },
  palette: {
    sand: createColor('#F7BF55'),
    error: createColor('#EF5D5D'),
  } as PaletteOptions,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1201,
      xl: 1536,
    },
  },
});

export default theme;
