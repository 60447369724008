import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  StatisticsScorecard,
  StatisticsDonutChartItem,
  StatisticsChartsRequestParam,
  StatisticsFiltersOptionsResponse,
  StatisticsGeneral,
  BatchAnalyticsResponse,
} from 'types/statistics';
import { subMonths } from 'date-fns';
import { fetchBatchStatistics, fetchStatisticsFilters } from './thunks';
import { translateCityName } from 'utils/localisation';
import i18next from 'i18next';

export interface StatisticsState {
  scorecard: StatisticsScorecard;
  donuts: {
    os: StatisticsDonutChartItem[];
    countries: StatisticsDonutChartItem[];
    cities: StatisticsDonutChartItem[];
  };
  filters: StatisticsChartsRequestParam;
  filtersOptions: StatisticsFiltersOptionsResponse;
  general: StatisticsGeneral[];
}

const now = new Date();
const currentTime = now.getTime();
const oneMonthAgo = subMonths(now, 1).getTime();

// Define the initial state using that type
const initialState: StatisticsState = {
  scorecard: {
    totalQrCodes: 0,
    totalScans: 0,
    uniqueScans: 0,
  },
  donuts: { os: [], countries: [], cities: [] },
  filters: {
    from: oneMonthAgo,
    to: currentTime,
    os: [],
    countries: [],
    cities: [],
    qrCodeIds: [],
  },
  filtersOptions: { qrCodes: [], os: [], countries: [], cities: [] },
  general: [],
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<StatisticsChartsRequestParam>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (qrCodes) => {
    qrCodes.addCase(fetchBatchStatistics.fulfilled, (state, action) => {
      const t = i18next.t;
      const payload = action.payload as BatchAnalyticsResponse;

      state.scorecard = payload.scorecard;
      state.donuts.cities = (payload.cities ?? []).map(({ city, ...stat }) => ({
        ...stat,
        label: translateCityName(city, t),
      }));
      state.donuts.countries = (payload.countries ?? []).map(({ country, ...stat }) => ({
        ...stat,
        label: country,
      }));
      state.donuts.os = (payload.os ?? []).map(({ os, ...stat }) => ({
        ...stat,
        label: os,
      }));
      state.general = payload.chart;
    });
    qrCodes.addCase(fetchBatchStatistics.rejected, (state, action) => {
      state.scorecard = initialState.scorecard;
      state.donuts.cities = initialState.donuts.cities;
      state.donuts.countries = initialState.donuts.countries;
      state.donuts.os = initialState.donuts.os;
      state.general = initialState.general ?? [];
    });

    // Filters
    qrCodes.addCase(
      fetchStatisticsFilters.fulfilled,
      (state, action: PayloadAction<StatisticsFiltersOptionsResponse>) => {
        state.filtersOptions = action.payload;
      }
    );
    qrCodes.addCase(fetchStatisticsFilters.rejected, (state) => {
      state.filtersOptions = initialState.filtersOptions;
    });
  },
});

export const { setFilters } = statisticsSlice.actions;

export default statisticsSlice.reducer;
