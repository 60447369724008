/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { axiosApiInstance, axiosInstanceForCloudinaryApi } from 'api/axios';
import { join, normalize, renameFile } from 'utils';
import { ROUTES } from 'constants/api.routes';
import { CLOUDINARY_BASE_API_URL, CLOUDINARY_BASE_CDN_URL, CLOUDINARY_CLOUD_NAME } from 'constants/api';
import { DefaultApiResponse } from 'types';
import { CloudinaryMediaDetailsDTO } from '../media/types';
import { GetUploadConfigApiResponse, CloudinaryApiUploadResponse } from './types';

class CloudinaryApi {
  public buildResourceURL = (entry: CloudinaryMediaDetailsDTO): string => {
    return `${CLOUDINARY_BASE_CDN_URL}/${CLOUDINARY_CLOUD_NAME}/${entry.resourceType}/upload/q_auto:good/${entry.publicId}`;
  };

  public buildResourceURLForPlayer = (entry: CloudinaryMediaDetailsDTO): string => {
    return `${this.buildResourceURL(entry)}.${entry.format}`;
  };

  public async uploadFile(file: File, onProgress?: (progress: number) => void): Promise<CloudinaryMediaDetailsDTO> {
    // Trigger progress
    onProgress && onProgress(1);

    const { data: cloudinaryUploadConfig }: AxiosResponse<GetUploadConfigApiResponse> = await axiosApiInstance.get(
      `${ROUTES.MEDIA.CLOUDINARY.UPLOAD_CONFIG}?safaricanbiteme="${Math.random()}"`
    );

    if (!cloudinaryUploadConfig) throw new Error('No Cloudinary upload config.');

    const config = {
      onUploadProgress(progressEvent: any) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress && onProgress(percentCompleted);
      },
    };

    const { signature, timestamp, apikey, cloudname, uploadPreset, id } = cloudinaryUploadConfig;

    const formData = new FormData();
    formData.append('file', renameFile(file, normalize(file.name || '')));
    formData.append('api_key', apikey);
    formData.append('timestamp', timestamp.toString());
    formData.append('signature', signature);
    formData.append('upload_preset', uploadPreset);
    formData.append('q_auto', ':good');
    formData.append('metadata', join('external_id=', id));

    const {
      data: { public_id, resource_type, format, bytes },
    }: AxiosResponse<CloudinaryApiUploadResponse> = await axiosInstanceForCloudinaryApi.post(
      `${CLOUDINARY_BASE_API_URL}/${cloudname}/auto/upload`,
      formData,
      config
    );

    await axiosApiInstance.post(ROUTES.MEDIA.CLOUDINARY.REPORT_UPLOAD, {
      publicId: public_id,
      resourceType: resource_type,
      externalId: id,
    });

    return {
      publicId: public_id,
      resourceType: resource_type,
      format,
      bytes,
    };
  }

  public async removeFile(id: string): Promise<DefaultApiResponse> {
    return axiosApiInstance.delete(join(ROUTES.MEDIA.CLOUDINARY.DELETE, id));
  }
}

const cloudinaryApi = new CloudinaryApi();

export default cloudinaryApi;
