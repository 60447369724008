import { createAsyncThunk } from '@reduxjs/toolkit';
import { statisticsApi } from 'api/statistics';
import { showNotification } from 'config/toast';
import { getValidErrorMessage } from 'utils';
import { StatisticsChartsRequestParam } from 'types/statistics';
import { RootState } from '../index';
import i18next from 'i18next';
import { translateCityName } from 'utils/localisation';

export const fetchBatchStatistics = createAsyncThunk(
  'statistics/fetchBatch',
  async (params: StatisticsChartsRequestParam) => {
    try {
      return statisticsApi.getStatisticsBatch(params);
    } catch (error: any) {
      console.error(error);
      showNotification(getValidErrorMessage(error), 'error');
      return error;
    }
  }
);

export const fetchStatisticsFilters = createAsyncThunk(
  'statistics/qr-filter',
  async (params: { language: string }, { getState }) => {
    try {
      const state = getState();

      const filters = await Promise.allSettled([
        statisticsApi.getStatisticsQrFilter(),
        statisticsApi.getStatisticsCountryFilter(params),
        statisticsApi.getStatisticsCitiesFilter((state as RootState).statistics.filters.countries),
        statisticsApi.getStatisticsOSFilter(),
      ]);
      const t = i18next.t;

      return {
        qrCodes: filters[0].status === 'fulfilled' ? filters[0].value : [],
        countries: filters[1].status === 'fulfilled' ? filters[1].value : [],
        cities:
          filters[2].status === 'fulfilled'
            ? filters[2].value.map((city) => ({ name: city, displayName: translateCityName(city, t) }))
            : [],
        os: filters[3].status === 'fulfilled' ? filters[3].value : [],
      };
    } catch (error: any) {
      console.error(error);
      showNotification(getValidErrorMessage(error), 'error');
      return error;
    }
  }
);
