/* eslint-disable @typescript-eslint/no-explicit-any */
import { RecursivePartial, ImageTypeEnum } from 'types';
import { STARTS_WITH_HTTP } from 'constants/regex';
import { AxiosError } from 'axios';
import objectPath from 'object-path';
import i18n from 'config/i18n';
import { Accept } from 'react-dropzone';
import { addMinutes, getTime } from 'date-fns';
import Base64 from './base64.js';
import { DEFAULT_LOCALE } from 'constants/languages';

export const ImageDownloadType = {
  [ImageTypeEnum.SVG]: 'svg+xml',
  [ImageTypeEnum.PNG]: 'png',
  [ImageTypeEnum.JPG]: 'jpg',
};

export const join = (...args: string[]): string => args.join('');

type ThrottleDebounceCallback = (...args: any[]) => any;

export function isObject(item: unknown): item is Record<string, unknown> {
  return !!item && typeof item === 'object' && !Array.isArray(item);
}

export const mergeTwoObjects = <T extends Record<string, any>>(
  firstObject: RecursivePartial<T>,
  secondObject: RecursivePartial<T>
): RecursivePartial<T> => {
  const keys = new Set([...Object.keys(firstObject), ...Object.keys(secondObject)]);
  return Array.from(keys.values()).reduce((mergedObject, key) => {
    let value = firstObject[key] ?? secondObject[key];
    if (firstObject[key] && secondObject[key] && isObject(firstObject[key]) && isObject(secondObject[key])) {
      value = mergeTwoObjects<T[string]>(firstObject[key] as T[string], secondObject[key] as T[string]);
    }
    // eslint-disable-next-line eqeqeq
    if (value != undefined) {
      mergedObject[key] = value;
    }
    return mergedObject;
  }, {} as Record<string, any>) as RecursivePartial<T>;
};

export const throttle = (callback: ThrottleDebounceCallback, delay: number): ThrottleDebounceCallback => {
  let timeoutId: number;
  let lastArgs: any[];

  return (...args: any[]): void => {
    lastArgs = args;

    if (timeoutId) return;

    timeoutId = window.setTimeout(() => callback(...lastArgs), delay);
  };
};

export const debounce = (callback: ThrottleDebounceCallback, delay: number): ThrottleDebounceCallback => {
  let timeoutId: number;
  let lastArgs: any[];

  return (...args: any[]): void => {
    lastArgs = args;

    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => callback(...lastArgs), delay);
  };
};

export const createDebounceClosure = (
  delay?: number
): ((callback: ThrottleDebounceCallback, ...args: unknown[]) => any) => {
  let timeoutId: number | null;

  return (callback: ThrottleDebounceCallback, ...args: unknown[]): void => {
    if (typeof delay !== 'number' || delay < 0 || isNaN(delay) || !isFinite(delay)) {
      callback(...args);
      return;
    }

    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      callback(...args);
      timeoutId = null;
    }, delay);
  };
};

export const createThrottleClosure = (
  delay?: number
): ((callback: ThrottleDebounceCallback, ...args: unknown[]) => any) => {
  let timeoutId: number | null;
  let lastArgs: any[];
  let lastCallback: ThrottleDebounceCallback;

  return (callback: ThrottleDebounceCallback, ...args: unknown[]): void => {
    if (typeof delay !== 'number' || delay < 0 || isNaN(delay) || !isFinite(delay)) {
      callback(...args);
      return;
    }

    lastCallback = callback;
    lastArgs = args;

    if (timeoutId) {
      return;
    }

    timeoutId = window.setTimeout(() => {
      lastCallback(...lastArgs);
      timeoutId = null;
    }, delay);
  };
};

export const encodeSVGStringToBase64 = (svg: string): string => {
  const div = document.createElement('div');
  div.innerHTML = svg;
  return `data:image/svg+xml;base64,${Base64.encode(div.children[0].outerHTML)}`;
};

export const decodeSVGStringFromBase64 = (base64svg: string): string =>
  Base64.decode(base64svg.replace('data:image/svg+xml;base64,', '')).replace(
    '<?xml version="1.0" standalone="no"?>',
    ''
  );

export const isAxiosError = <T = any>(error: any): error is AxiosError<T> => {
  if (!error) return false;

  if (typeof error !== 'object') return false;

  return Boolean(error.isAxiosError);
};

export const getValidErrorMessage = (error: any): string => {
  let errorMessage = error.message || '';

  if (error instanceof Error) {
    errorMessage = error.message;
  }

  if (error.cause === 'SubscriptionCancellation') {
    errorMessage = i18n.t(error.message);
  }

  if (isAxiosError(error)) {
    errorMessage = i18n.t(error.response?.data?.errorMessage || error.response?.data?.message);
  }

  return errorMessage;
};

export const downloadImage = (file: string, fileName: string, fileType: ImageTypeEnum): void => {
  const blob = new Blob([file], { type: `image/${ImageDownloadType[fileType]}` });

  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;

  link.setAttribute('download', `${fileName}.${fileType}`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.removeChild(link);
};

export const megabytesToBytes = (count: number): number => count * 1048576;

export const isValidFileSize = (file: File, maxSize?: number): boolean =>
  !file.size || !maxSize || file.size <= megabytesToBytes(maxSize * 1.182); // 1.182 is coefficient for base64 encoding to provide correct size limit for image upload from disk

export const getLanguageName = (lang?: string): string => {
  if (!lang) {
    return '';
  }
  return (
    new Intl.DisplayNames([], {
      type: 'language',
      style: 'narrow',
    }).of(lang) || ''
  );
};

export const capitalize = (str: string): string => {
  return str[0].toLocaleUpperCase() + str.slice(1);
};

export const getModifiedLocale = (lang: string, path?: string): string => {
  return lang === DEFAULT_LOCALE ? path ?? '' : `/${lang}${path?.replace('/', '') ? path : ''}`;
};

export const mapYupErrorsToSchemaLikeObject = (yupErrors: {
  inner: Array<{ path: string; message: string }>;
}): Record<string, any> => {
  const errors = {};
  if (!yupErrors?.inner?.length) {
    return {};
  }
  yupErrors.inner.forEach((error) => {
    objectPath.set(errors, error.path, error.message);
  });
  return errors;
};

export const addURLToLink = (link: string): string => {
  if (!STARTS_WITH_HTTP.test(link)) {
    return join('https://', link);
  }
  return link;
};

export const downloadFile = ({
  url,
  fileName,
  target = '_blank',
  rel = 'noopener noreferrer',
}: {
  url: string;
  fileName?: string;
  target?: string;
  rel?: string;
}): void => {
  const link = document.createElement('a');
  link.href = url;
  if (target) link.target = target;
  if (fileName) link.download = fileName;
  if (rel) link.rel = rel;
  link.click();
};

export const dropzoneAcceptToString = (entry: Accept): string => {
  return Object.entries(entry)
    .flatMap((entry) => entry)
    .flat()
    .join(',');
};

export const bytesToMegabytes = (count: number): number => count / 1048576;

export const getUTCDate = (date: Date): Date => addMinutes(date, date.getTimezoneOffset() * -1);

export const getUTCDateTime = (date: Date): number => getTime(getUTCDate(date));

export const isSSR = (): boolean => {
  const canUseDom = typeof window !== 'undefined' && window.document && window.document.createElement;
  return !canUseDom;
};

export const getLastURLSegment = (url: string): string => url.split('/').pop() || '';

export const joinPhrases = (phrases: string[]): string =>
  phrases.reduce((acc: string, phrase: string) => {
    if (phrase.trim().endsWith('.')) {
      return `${acc} ${phrase.trim()}`;
    }
    return `${acc} ${phrase.trim()}.`;
  }, '');

export const normalize = (str: string): string => str.normalize('NFC');

export const renameFile = (originalFile: File, newName: string): File => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

export const getCurrencySymbol = (locale: string | undefined, currency: string): string =>
  (0)
    .toLocaleString(locale || DEFAULT_LOCALE, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

// prices from array are exact and should not be divided
export const isExactPrice = (currency: string): boolean => ['JPY', 'CLP', 'KRW', 'VND'].includes(currency);
