import { createAsyncThunk } from '@reduxjs/toolkit';
import { qrCodeApi } from 'api/qrCode/apis';
import { ApiErrorCodes } from 'constants/api';
import { EventTypes, globalEventEmitter } from 'providers/EventEmitterProvider';
import { isAxiosError } from 'utils';

export const fetchQRCodes = createAsyncThunk('qrCodes/fetchQRCodes', async () => qrCodeApi.getMany());

export const deleteQRCodes = createAsyncThunk('qrCodes/delete', async (ids: string[], { dispatch }) => {
  await qrCodeApi.delete(ids);
  dispatch(fetchQRCodes());
});

export const activateQrCodes = createAsyncThunk('qrCodes/activate', async (ids: string[], { dispatch }) => {
  try {
    await qrCodeApi.activate(ids);

    dispatch(fetchQRCodes());
  } catch (error) {
    if (!isAxiosError(error)) throw error;

    const { response } = error;

    if (response?.status === ApiErrorCodes.UNAVAILABLE_FOR_LEGAL_REASON) {
      globalEventEmitter.dispatchEvent({
        type: EventTypes.QrLeadsToMalware,
      });

      return;
    }

    throw error;
  }
});

export const deactivateQrCodes = createAsyncThunk('qrCodes/deactivate', async (ids: string[], { dispatch }) => {
  await qrCodeApi.deactivate(ids);
  dispatch(fetchQRCodes());
});

export const resetScans = createAsyncThunk('qrCodes/resetScans', async (ids: string[], { dispatch }) => {
  await qrCodeApi.resetScans(ids);
  dispatch(fetchQRCodes());
});
