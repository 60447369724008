/**
 * Util for retrying failed functions
 * @param fn Function to retry
 * @param maxRetries Max number of retries
 * @param delay Delay between retries
 */
export const retry = async (fn: () => Promise<unknown>, maxRetries = 1, delay = 300): Promise<any> => {
  try {
    return await fn();
  } catch (error) {
    if (maxRetries < 1) {
      throw error;
    }
    await new Promise((resolve) => {
      setTimeout(resolve, delay);
    });

    return retry(fn, maxRetries - 1, delay);
  }
};
