import { RecursivePartial } from 'types';
import { MediaProviders, NoProviderMediaDetailsDTO } from '../types';

const createNoProviderMediaDetailsDTO = (
  entry?: RecursivePartial<NoProviderMediaDetailsDTO> | null
): NoProviderMediaDetailsDTO => {
  return {
    provider: MediaProviders.NONE,
    src: entry?.src || '',
  };
};

export default createNoProviderMediaDetailsDTO;
