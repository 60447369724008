import { createAsyncThunk } from '@reduxjs/toolkit';
import { qrCodeApi } from 'api/qrCode/apis';
import { QRCodeDTO } from 'api/qrCode/types';
import { templateCategoriesApi } from 'api/templateCategories';
import { EventTypes, globalEventEmitter } from 'providers/EventEmitterProvider';

type EntryWithID = { id: string; entry: QRCodeDTO };

export const getTemplateCategories = createAsyncThunk('builder/getTemplateCategories', async () => {
  return templateCategoriesApi.getMany();
});

export const getPreviewQRCode = createAsyncThunk('builder/getPreviewQRCode', async (id: string) => {
  return qrCodeApi.getOne(id);
});

export const createQRCode = createAsyncThunk(
  'builder/createQRCode',
  async (entry: QRCodeDTO): Promise<QRCodeDTO | undefined> => {
    const resp = await qrCodeApi.create(entry);

    if (!resp.validation.invalidUrls?.length) {
      return qrCodeApi.getOne(resp.id);
    }

    globalEventEmitter.dispatchEvent({
      type: EventTypes.MaliciousUrlsInQRCode,
      details: {
        urls: resp.validation.invalidUrls,
      },
    });

    return undefined;
  }
);

export const getGuestPreviewQRCode = createAsyncThunk('builder/getGuestPreviewQRCode', async (id: string) => {
  return qrCodeApi.getOneAsGuest(id);
});

export const createGuestQRCode = createAsyncThunk(
  'builder/createGuestQRCode',
  async (entry: QRCodeDTO): Promise<QRCodeDTO | undefined> => {
    const resp = await qrCodeApi.createAsGuest(entry);

    if (!resp.validation.invalidUrls?.length) {
      return qrCodeApi.getOneAsGuest(resp.id);
    }

    globalEventEmitter.dispatchEvent({
      type: EventTypes.MaliciousUrlsInQRCode,
      details: {
        urls: resp.validation.invalidUrls,
      },
    });

    return undefined;
  }
);

export const updateGuestQRCode = createAsyncThunk('builder/updateGuestQRCode', async ({ id, entry }: EntryWithID) => {
  const resp = await qrCodeApi.updateAsGuest(id, entry);

  if (!resp.data.validation.invalidUrls.length) return qrCodeApi.getOneAsGuest(id);

  globalEventEmitter.dispatchEvent({
    type: EventTypes.MaliciousUrlsInQRCode,
    details: {
      urls: resp.data.validation.invalidUrls,
    },
  });

  return undefined;
});

export const updateQRCode = createAsyncThunk('builder/updateQRCode', async ({ id, entry }: EntryWithID) => {
  const resp = await qrCodeApi.update(id, entry);

  if (!resp.data.validation.invalidUrls.length) return qrCodeApi.getOne(id);

  globalEventEmitter.dispatchEvent({
    type: EventTypes.MaliciousUrlsInQRCode,
    details: {
      urls: resp.data.validation.invalidUrls,
    },
  });

  return undefined;
});

export const getEditQRCodes = createAsyncThunk('builder/getEditQRCodes', async (premanentQRCodeId: string) => {
  const permanentQRCode = await qrCodeApi.getOne(premanentQRCodeId);
  const previewQRCodeCreateResponse = await qrCodeApi.create(permanentQRCode);
  const QRCode = await qrCodeApi.getOne(previewQRCodeCreateResponse.id);

  return {
    permanentQRCode,
    QRCode,
  };
});

export const getPermanentQRCode = createAsyncThunk('builder/getPermanentQRCode', async (id: string) => {
  return qrCodeApi.getOne(id);
});
