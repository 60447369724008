import { ContactsDTO } from '../types';

export const createContactsDTO = (entry?: Partial<ContactsDTO>): ContactsDTO => {
  return {
    phoneNumber: entry?.phoneNumber || '',
    alternativePhoneNumber: entry?.alternativePhoneNumber || '',
    email: entry?.email || '',
    website: entry?.website || '',
  };
};
