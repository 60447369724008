import { toast, ToastContent } from 'react-toastify';

export type NotificationType = 'success' | 'error' | 'info' | 'warn';

export const showNotification = (
  message: ToastContent,
  type: NotificationType,
  onClose?: () => void,
  toastId?: string
): void => {
  let toastIdInner;

  if (toastId) {
    toastIdInner = toastId;
  } else if (typeof message === 'string' || typeof message === 'number') {
    toastIdInner = message;
  }

  toast[type](message, {
    position: 'top-center',
    icon: true,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: toastIdInner,
    theme: 'colored',
    onClose,
  });
};
