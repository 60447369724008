export enum MediaProviders {
  CLOUDINARY = 'cloudinary',
  NONE = 'none',
}

export type CloudinaryMediaDetailsDTO = {
  publicId: string;
  resourceType: string;
  format: string;
  bytes: number;
};

export interface MediaWithProviderBaseDTO {
  provider: `${MediaProviders}`;
}

export interface NoProviderMediaDetailsDTO extends MediaWithProviderBaseDTO {
  provider: MediaProviders.NONE;
  src: string;
}

export interface CloudinaryProviderMediaDetailsDTO extends MediaWithProviderBaseDTO {
  provider: MediaProviders.CLOUDINARY;
  publicId: string;
  resourceType: string;
  format: string;
  bytes: number;
}

export type MediaWithProviderDetailsDTO = CloudinaryProviderMediaDetailsDTO | NoProviderMediaDetailsDTO;
