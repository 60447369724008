import { Language } from 'types/account';

class UserSettingsCache {
  private static instance: UserSettingsCache;
  private static readonly CACHE_EXPIRATION_MS = 3600000; // 1 hour in milliseconds

  private constructor() {}

  public static getInstance(): UserSettingsCache {
    if (!UserSettingsCache.instance) {
      UserSettingsCache.instance = new UserSettingsCache();
    }
    return UserSettingsCache.instance;
  }

  public getLanguages(): Language[] | null {
    const cachedData = localStorage.getItem('languages');
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < UserSettingsCache.CACHE_EXPIRATION_MS) {
        return data;
      }
      localStorage.removeItem('languages');
    }
    return null;
  }

  public setLanguages(languages: Language[]): void {
    const cacheEntry = {
      data: languages,
      timestamp: Date.now(),
    };
    localStorage.setItem('languages', JSON.stringify(cacheEntry));
  }

  public getCountries(language: string): any {
    const cachedData = localStorage.getItem(`countries_${language}`);
    return cachedData ? JSON.parse(cachedData) : null;
  }

  public setCountries(language: string, countries: any): void {
    localStorage.setItem(`countries_${language}`, JSON.stringify(countries));
  }
}

export default UserSettingsCache;
