const sanitizeUrl = (url?: string): string => {
  if (typeof url !== 'string') return '';
  if (url.endsWith('/')) return url.slice(0, -1);
  return url;
};

export const BASE_URL = sanitizeUrl(process.env.REACT_APP_API_URL);
export const BASE_MOBI_URL = sanitizeUrl(process.env.REACT_APP_BASE_MOBI_URL);
export const CLOUDINARY_BASE_CDN_URL = sanitizeUrl(
  process.env.REACT_APP_CLOUDINARY_BASE_CDN_URL || 'https://res.cloudinary.com'
);
export const CLOUDINARY_BASE_API_URL = sanitizeUrl(
  process.env.REACT_APP_CLOUDINARY_BASE_API_URL || 'https://api.cloudinary.com/v1_1'
);
export const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || '';
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';

export enum ApiErrorCodes {
  UNAVAILABLE_FOR_LEGAL_REASON = 451,
}
