export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type PartialExcept<T, K extends keyof T> = RecursivePartial<T> & Pick<T, K>;

export type DefaultApiResponse = {
  data: {
    message: string;
  };
};

export type TextTranslation = {
  text: string;
  fallback: string;
};

export enum SortEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ImageTypeEnum {
  SVG = 'svg',
  PNG = 'png',
  JPG = 'jpeg',
}

export enum ReportTypeEnum {
  CSV = 'csv',
  XLSX = 'xlsx',
}

export type SelectOption = {
  value: string;
  label: string;
};

export type SortedSelectOption = {
  value: string;
  label: string;
  direction: SortEnum;
};

export type ModalCoords = { x?: number; y: number };

export type ImageTypeValue = `${ImageTypeEnum}`;

export enum RedirectFlowParamsEnum {
  REACTIVATE = 'reactivate',
  PRICING = 'pricing',
  CHECKOUT = 'checkout',
}

export enum PopupEnum {
  CONDITIONAL = 'conditional',
  BUTTON = 'button',
  CLIPBOARD = 'clipboard',
}

export type ChartLegendOption = {
  color: string;
  label: string;
  count: string;
};

export enum PaymentOptionsEnum {
  CREDIT_CARD = 'creditCard',
  PAYPAL = 'paypal',
  APPLE_PAY = 'applePay',
  GOOGLE_PAY = 'googlePay',
}
