import { StripeElementLocale } from '@stripe/stripe-js';

type LanguageToStripeLocale = {
  [K in (typeof SUPPORTED_LANGUAGES)[number]]: StripeElementLocale; // eslint-disable-line
};

export const ALLOWED_LANGUAGES = process.env.NEXT_ALLOWED_LOCALIZATION?.split(',') ?? [];
export const ALLOWED_LANGUAGES_SET = new Set(ALLOWED_LANGUAGES);
export const DEFAULT_LOCALE = ALLOWED_LANGUAGES[0];
export type ImageLocales = 'es' | 'en';

export const SUPPORTED_LANGUAGES = ['en-US', 'en', 'ru-UA'];
export const I18N_LANGUAGE_TO_STRIPE_LOCALE: LanguageToStripeLocale = {
  'en-US': 'en',
  'ru-UA': 'ru',
};
