import isEqual from 'react-fast-compare';
import { TemplateDTO } from 'api/qrCode/types';
import { AUTH_COOKIE_PARAM } from 'constants/constants';
import { GetServerSidePropsContext } from 'next/types';

type RecordWithStringModificationDates = {
  createdAt?: string;
  updatedAt?: string;
};

type ConvertObjectStringModificationDatesToDatesOrNull = <T extends RecordWithStringModificationDates>(
  entry: T
) => Omit<T, 'createdAt' | 'updatedAt'> & { createdAt: Date | null; updatedAt: Date | null };

export const convertObjectModificationDatesFromStringToDatesOrNull: ConvertObjectStringModificationDatesToDatesOrNull =
  (entry) => ({
    ...entry,
    createdAt: entry.createdAt ? new Date(entry.createdAt) : null,
    updatedAt: entry.updatedAt ? new Date(entry.updatedAt) : null,
  });

export const areQRContentsEqual = (templateA: TemplateDTO, templateB: TemplateDTO): boolean => {
  if (templateA && templateB) {
    const { name: templateAName, ...templateUpdateFields } = templateA;
    const { name: templateBName, ...selectedTemplateFields } = templateB;

    return isEqual(templateUpdateFields, selectedTemplateFields);
  }

  return templateA === templateB;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const setAuthCookie = (token: string, role?: string) => {
  document.cookie = `${AUTH_COOKIE_PARAM}=${token}; path=/;`;
  document.cookie = `role=${role ?? ''}; path=/;`;
};

// eslint-disable-next-line consistent-return
export const getCookie = (param: string): any => {
  if (typeof window !== 'object') return null;

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${param}=`);
  if (parts && parts.length === 2) return parts.pop()?.split(';').shift();
};

export const getAuthToken = ({ req }: GetServerSidePropsContext): boolean => {
  return Boolean(req.cookies[AUTH_COOKIE_PARAM]);
};
