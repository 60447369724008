import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import createCloudinaryMediaDetailsDTO from 'api/media/entities/createCloudinaryMediaDetailsDTO';
import { PdfDTO } from '../types/PdfDTO';
import { createColorsDTO } from './createColorsDTO';

export const createPdfDTO = (entry?: RecursivePartial<PdfDTO>): PdfDTO => {
  return {
    type: QRCodeTypeEnum.PDF,
    name: entry?.name || '',
    file: createCloudinaryMediaDetailsDTO(entry?.file),
    isDirect: entry?.isDirect ?? false,
    colors: createColorsDTO(entry?.colors),
    company: {
      name: entry?.company?.name || '',
      title: entry?.company?.title || '',
      description: entry?.company?.description || '',
      website: entry?.company?.website || '',
    },
    loaderImage: createCloudinaryMediaDetailsDTO(entry?.loaderImage),
  };
};
