import { QRCodeTypeEnum } from 'types/qrCodes';
import { createVCardDTO } from './createVCardDTO';
import { createWebsiteDTO } from './createWebsiteDTO';
import { createAppDTO } from './createAppDto';
import { createTextDTO } from './createTextDTO';
import { createVideoDTO } from './createVideoDTO';
import { createBusinessPageDTO } from './createBusinessPageDTO';
import { createWiFiDTO } from './createWiFiDTO';
import { createPdfDTO } from './createPdfDTO';
import { createImageGalleryDTO } from './createImageGalleryDTO';
import { createMenuDTO } from './createMenuDTO';
import { createFacebookDTO } from './createFacebookDTO';
import { createSocialMediaDTO } from './createSocialMediaDTO';
import { TemplateFactory } from '../types';

type TemplateLibrary = {
  [keyName in QRCodeTypeEnum]: TemplateFactory;
} & { default: null };

const templateLibrary: TemplateLibrary = {
  [QRCodeTypeEnum.V_CARD]: createVCardDTO as TemplateFactory,
  [QRCodeTypeEnum.URL]: createWebsiteDTO as TemplateFactory,
  [QRCodeTypeEnum.APP]: createAppDTO as TemplateFactory,
  [QRCodeTypeEnum.PLAIN_TEXT]: createTextDTO as TemplateFactory,
  [QRCodeTypeEnum.BUSINESS_PAGE]: createBusinessPageDTO as TemplateFactory,
  [QRCodeTypeEnum.VIDEO]: createVideoDTO as TemplateFactory,
  [QRCodeTypeEnum.WIFI]: createWiFiDTO as TemplateFactory,
  [QRCodeTypeEnum.PDF]: createPdfDTO as TemplateFactory,
  [QRCodeTypeEnum.IMAGES]: createImageGalleryDTO as TemplateFactory,
  [QRCodeTypeEnum.MENU]: createMenuDTO as TemplateFactory,
  [QRCodeTypeEnum.FACEBOOK]: createFacebookDTO as TemplateFactory,
  [QRCodeTypeEnum.SOCIAL_MEDIA]: createSocialMediaDTO as TemplateFactory,
  default: null,
};

export default templateLibrary;
