import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { BillingTransaction } from 'types/billing';
import { HYDRATE } from 'next-redux-wrapper';
import { localStorageService } from 'libs/localStorage/local.storage.service';
import { SubscriptionProduct, Subscription, Currency } from 'types/subscriptions';
import {
  fetchSubscriptionProduct,
  fetchCurrentUserSubscription,
  fetchUserTransaction,
  fetchPaymentDetails,
  fetchCurrencies,
} from './thunks';
import { logout } from 'store/auth/thunks';

export type SubscriptionState = {
  subscription: Subscription | null;
  products: SubscriptionProduct[];
  currencies: Currency[];
  activeProduct: SubscriptionProduct | null;
  transactions: BillingTransaction[];
  paymentDetails: string;
};

const initialState: SubscriptionState = {
  products: [],
  currencies: [],
  transactions: [],
  activeProduct: null,
  subscription: null,
  paymentDetails: '',
};

const setActiveProductsFromProducts = (state: SubscriptionState, products: SubscriptionProduct[]) => {
  state.products = products;
  const activeProduct = products.find((product) => product.isFavourite) || null;
  state.activeProduct = activeProduct;
  localStorageService.setActiveProductId(activeProduct?.id || '');
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionsProducts: (state, { payload }: PayloadAction<SubscriptionProduct[]>) => {
      setActiveProductsFromProducts(state, payload);
    },
    setActiveProductById: (state, { payload }: PayloadAction<string>) => {
      const product = state.products.find((product: SubscriptionProduct) => product.id === payload) || null;

      if (product) {
        state.activeProduct = product;
        localStorageService.setActiveProductId(payload);
      }
    },
    setActiveProduct: (state, { payload }: PayloadAction<SubscriptionProduct>) => {
      state.activeProduct = payload;
      localStorageService.setActiveProductId(payload.id);
    },
  },
  extraReducers: (subscription) => {
    subscription.addCase(fetchSubscriptionProduct.pending, (state) => {
      state.products = [];
      state.activeProduct = null;
      localStorageService.setActiveProductId('');
    });
    subscription
      .addCase(fetchSubscriptionProduct.fulfilled, (state, action) => {
        setActiveProductsFromProducts(state, action.payload);
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.products = [];
        state.activeProduct = null;
        localStorageService.setActiveProductId('');
      });
    subscription.addCase(fetchCurrencies.fulfilled, (state, action) => {
      state.currencies = action.payload;
    });
    subscription.addCase(fetchCurrentUserSubscription.fulfilled, (state, action) => {
      state.subscription = action.payload;
    });
    subscription.addCase(fetchUserTransaction.fulfilled, (state, action) => {
      state.transactions = action.payload.sort(
        (a: BillingTransaction, b: BillingTransaction) =>
          new Date(b.transactionDate).getTime() - new Date(a.transactionDate).getTime()
      );
    });
    subscription.addCase(fetchPaymentDetails.fulfilled, (state, action) => {
      state.paymentDetails = action.payload.paymentDetails;
    });
    subscription.addCase(HYDRATE, (state, action: AnyAction) => {
      return { ...state, ...action.payload.subscription };
    });
  },
});

export const { setActiveProductById, setSubscriptionsProducts, setActiveProduct } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
